import React, { useEffect, useState } from "react";
import Select, { components } from 'react-select';

const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '45px',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: '3px',
      borderBottomRightRadius: '3px',
      boxShadow: state.isFocused ? null : null,
      fontSize: '13px',
      borderColor: "#696969",
      borderWidth: '1px',
    }),
    menu: provided => ({ ...provided, zIndex: 10 })
}

export const InputSelect = ({label, value, options, change, isMulti, isDisabled, loading, noOptionsText, setSearch, removeMargin}) => {
    const [option, setOption] = useState(null);

    useEffect(() => {
        if(!isMulti){
            let arr = options ? options.filter(opt => opt.value === value) : [];
            setOption(arr[0]);
        } else {
            let arr = options ? options.filter(opt => value.includes(opt.value)) : [];
            setOption(arr);
        }
    }, [value, options]);

    const handleOnChange = value => {
        if(!isMulti){
            change(value.value);
            return;
        } else {
            let values = [];
            value.map(option => values.push(option.value));
            change(values);
        }
    }

    const NoOptionsMessage = props => {
        return (
          <components.NoOptionsMessage {...props}>
            <span className="custom-css-class">{noOptionsText ?? 'Nenhum resultado'}</span> 
          </components.NoOptionsMessage>
        );
    }


    if(label === 'Componente'){
        console.log(Array.isArray(option), options, 'here');
    }

    return (
        <div className="select-group" style={removeMargin ? { margin: 0} : {}}>
            <label>{label}</label>
            <Select 
                isMulti={isMulti ? true : false}
                isDisabled={isDisabled || loading === true ? true : false}
                components={{ NoOptionsMessage }}
                placeholder={loading === true ? 'Carregando...' : 'Selecione...'}
                value={Array.isArray(option) ? option :  options.filter(function(option) {
                    return option.value === value;
                })}          
                options={options ? options : []}
                formatOptionLabel={item => (
                    <div className="select-label">
                        {item?.image}
                        {item.label}
                    </div>
                )}
                onInputChange={(value, action) => {
                    if(setSearch && action.action === 'input-change'){
                        setSearch(value)
                    }
                    }}
                onChange={handleOnChange}
                styles={customStyles}
            />
        </div>
    );
}

export default InputSelect;
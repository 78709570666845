import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Echo from "laravel-echo";

import { useLocation } from "react-router-dom";
import { getUser, getPlano, hasPermission, logout } from "../../services/auth";
import Button from "../Button";
import api from "../../services/api";

export const SidebarItem = ({item, user}) => {
    const [active, setActive] = useState(false);

    const location = useLocation();
    const pathname = location.pathname;
    
    useEffect(() => {
        if(pathname == item.to){
            setActive(true);
        }
        if(item.subitems){
            item.subitems.map(subitem => {
                if(subitem.to === pathname){
                    setActive(true);
                }
            });
        }
    }, [location]);

    return (
        <li className={active ? 'active' : ''}>
            {!item.subitems && 
                <Link to={item.to}>
                    <img src={ require(`../../assets/images/svgs/${item.svg}.svg`)} alt={item.name} />
                    {item.name}
                    {item?.alert !== undefined && item?.alert !== 0 &&
                        <span className="alert">{item.alert}</span>
                    }
                </Link>
            }
            {item.subitems && 
                <a onClick={() => setActive(prev => !prev)}>
                    <img src={ require(`../../assets/images/svgs/${item.svg}.svg`)} alt={item.name} />
                    {item.name}
                    {item.subitems &&
                        <img src={ require(`../../assets/images/svgs/arrow.svg`).default} className="arrow" alt="alterar" />
                    }
                </a>
            }
            
            {item.subitems &&
                <ul>
                    {item.subitems.map((subitem, index) => {
                        if(subitem.show){
                            return (
                                <SidebarSubitem subitem={subitem} key={index} />
                            );
                        }   
                    })}
                </ul>
            }
        </li>
    );
}

export const SidebarSubitem = ({subitem}) => {
    const [active, setActive] = useState(false);

    const location = useLocation();
    
    useEffect(() => {
        if(location.pathname === subitem.to){
            setActive(true);
        }
    }, []);

    return (
        <li className={active ? 'active' : ''}>
            <Link to={subitem.to}>
                <span className="dot"></span>
                {subitem.name}
            </Link>
        </li>
    );
}


export const Sidebar = () => {
    const user = getUser();
    
    const navigate = useNavigate();
    const [items, setItems] = useState([]);

    useEffect(() => {
        getItems();
    }, []);

    const signOut = e => {
        e.preventDefault();
        logout();
        navigate(`/login`);
    }

    const getItems = () => {
        const base = [           
            { 
                name: 'Clientes', 
                svg: 'company', 
                to: '/tenant/list',
                show: true
            },
            { 
                name: 'Planos', 
                svg: 'plan', 
                to: '/plan/list',
                show: true
            },
            { 
                name: 'Administradores', 
                svg: 'user',
                to: '/superadmin/list',
                show: hasPermission(['admin', 'role'])
            }
        ];

        setItems(base);
    }

    
    return (
        <aside id="sidebar">
            <div id="user">
                <p>Olá, {user?.name}</p>
                <p><a href="#" onClick={signOut}>Sair</a></p>
            </div>
            <div id="menu">
                <ul>
                    {items.map((item, index) => {
                        if(item.show){
                            return (
                                <SidebarItem item={item} key={index} user={user} />
                                );
                            }
                        })}
                </ul>
            </div>
            
        </aside>
    );
}

export default Sidebar;
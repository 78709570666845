import TenantEdit from "../pages/Tenant/edit";
import TenantList from "../pages/Tenant/list";
import TenantCreate from "../pages/Tenant/create";
import SuperAdminList from '../pages/SuperAdmin/list';
import PlanList from "../pages/Plan/list";

export const privateRoutes = [
    {
        path: "/tenant/list",
        element: <TenantList />,
        show: true,
        main: true
    },
    {
        path: "/tenant/edit/:id",
        element: <TenantEdit />,
        show: true,
        main: false
    },
    {
        path: "/tenant/create",
        element: <TenantCreate />,
        show: true,
        main: false
    },
    {
        path: "/superadmin/list",
        element: <SuperAdminList />,
        show: true,
        main: true
    },
    {
        path: "/plan/list",
        element: <PlanList />,
        show: true,
        main: true
    }
];

export const getInitialPath = () => {
    let path = null;

    for(let i = 0; i < privateRoutes.length; i++){
        console.log(privateRoutes, path = privateRoutes[i].path, privateRoutes[i].show && privateRoutes[i].main)
        if(privateRoutes[i].show && privateRoutes[i].main){
            path = privateRoutes[i].path;
            break;
        }
    }
    
    return path;
}

export default privateRoutes;
import React, { useEffect, useState } from "react";

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import moment from "moment";
import PageHeader from "../../components/PageHeader";
import Button from "../../components/Button";
import FilterSimple from "../../components/FilterSimple";

export const TenantList = () => {
    const [loading, setLoading] = useState(true);
    const [tenants, setTenants] = useState([]);
    const [data, setData] = useState({header: [], rows: []});

    const [filters, setFilters] = useState('');

    useEffect(() => {
        getTenants();
    }, [filters])

    useEffect(() => {
        generateData();
    }, [tenants])
    
    const getTenants = () => {
        setLoading(true);

        api.get(`/tenant?${filters}`).then(res => {
            setTenants(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        let header = ['Logo', 'Nome', 'Plano', 'Site', 'Cadastrado em', 'Sistema', 'Site'];
        const rows = [];

        tenants.map(tenant => {
            let image =  <img src={ require(`../../assets/images/svgs/default.svg`).default } />;

            if(tenant.logo_svg){
                image = <img src={`${tenant.logo_svg}?t=${new Date().getTime()}`} />
            } else if (tenant.logo_png){
                image = <img src={`${tenant.logo_png}?t=${new Date().getTime()}`} />
            }

            rows.push({
                data: [
                    image,
                    tenant.nome,
                    tenant?.plan?.name ?? '',
                    tenant.status,
                    tenant.created_at ? moment(tenant.created_at).format('DD/MM/YYYY HH:mm') : ''
                ],
                to:`/tenant/edit/${tenant.id}`,
                buttons: [
                    <a href={`https://${tenant.domain}`} target="_blank">{tenant.domain}</a>,
                    <a href={`https://${tenant.subdomain}`} target="_blank">{tenant.subdomain}</a>
                ]
            })
        });

        setData({header, rows});
    }

    const handleSetFilter = value => {
        setFilters(value);
    }

    return (
        <>
            <PanelTemplate id="tenant-list">
                <PageHeader
                    left={
                    <FilterSimple
                            params={[
                                {name: 'Nome', key: 'nome', type: 'text' },
                            ]}
                            filter={handleSetFilter}
                        />
                    }
                    right={
                        <Button 
                            type={`primary`} 
                            size={`small`} 
                            text={`Cadastrar cliente`} 
                            svg="plus-white" 
                            link={`/tenant/create`}
                        />
                    }
                />
                <Box className={`no-padding`}>
                    <Table 
                        header={data.header} 
                        rows={data.rows} 
                        loading={loading}
                    />
                </Box>
            </PanelTemplate>
        </>
    );
}

export default TenantList;
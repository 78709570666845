import React, { useEffect, useState } from "react";

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import PageHeader from "../../components/PageHeader";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import { toast } from "react-toastify";
import InputCheckboxUnique from "../../components/InputCheckboxUnique";
import { ModalDelete } from "../../components/ModalDelete";


const SuperAdminList = () => {
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [data, setData] = useState({header: [], rows: []});

    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [updatePassword, setUpdatePassword] = useState(false);

    const [idToDelete, setIdToDelete] = useState(null);

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        generateData();
    }, [users]);

    useEffect(() => {
        if(!showCreate) clean();
    }, [showCreate]);

    useEffect(() => {
        if(!showEdit) clean();
    }, [showEdit]);

    useEffect(() => {
        setPassword('');
    }, [updatePassword])

    const getUsers = () => {
        api.get('/superadmin').then(res => {
            setUsers(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = ['Nome', 'E-mail'];
        const rows = [];

        users.map(user => {
            rows.push({
                data: [
                    user.name,
                    user.email
                ],
                action: () => editUser(user)
            });
        });

        setData({header, rows});
    }

    const storeUser = () => {
        setLoading(true);

        api.post(`/superadmin`, {
            name,
            email,
            password
        }).then(res => {
            getUsers();
            setShowCreate(false);
            toast.success('Usuário cadastrado com sucesso');
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const editUser = user => {
        setId(user.id);
        setName(user.name);
        setEmail(user.email);
        setShowEdit(true);
    }

    const updateUser = () => {
        setLoading(true);

        api.put(`/superadmin/${id}`, {
            name,
            email,
            password
        }).then(res => {
            getUsers();
            setShowEdit(false);
            toast.success('Usuário atualizado com sucesso');
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const confirmDeleteUser = () => {
        setIdToDelete(id);
        setShowEdit(false);
    }

    const deleteUser = () => {
        setLoading(true);

        api.delete(`/superadmin/${idToDelete}`).then(res => {
            setIdToDelete(null);
            getUsers();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const clean = () => {
        setId('');
        setName('');
        setEmail('');
        setPassword('');
        setUpdatePassword(false);
    }

    return (
        <>
            <ModalDelete
                close={() => setIdToDelete(null)}
                show={idToDelete !== null}
                action={deleteUser}
                loading={loading}
            />
            <Modal
                title={`${showCreate ? 'Cadastrar' : 'Editar'} usuário`}
                show={showCreate || showEdit}
                close={() => showCreate ? setShowCreate(false) : setShowEdit(false)}
                footer={
                    <>
                        {showEdit &&
                            <Button
                                type={`secondary`}
                                size={`small`}
                                text={`Excluir`}
                                svg={`delete-small`}
                                action={() => confirmDeleteUser()}
                                loading={loading} 
                            />
                        }
                        <Button
                            type={`primary`}
                            size={`small`}
                            text={showCreate ? 'Cadastrar' : 'Salvar alterações'}
                            svg={`save-white`}
                            action={() => showCreate ? storeUser() : updateUser()}
                            loading={loading} 
                        />
                        
                    </>
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <Input type={`text`} label={`Nome`} value={name} change={setName} autoComplete={`new-password`} />
                        </div>
                        <div className="col-6">
                            <Input type={`email`} label={`E-mail`} value={email} change={setEmail} autoComplete={`new-password`} />
                        </div>
                    </div>
                    {showEdit &&
                        <InputCheckboxUnique label={`Alterar senha`} checked={updatePassword} change={setUpdatePassword} />
                    }
                    {(showCreate || updatePassword) &&
                        <div className="row">
                            <div className="col-6">
                                <Input type={`password`} label={`Senha`} value={password} change={setPassword} autoComplete={`new-password`} />
                            </div>
                        </div>
                    }
                </div>
            </Modal>
            <PanelTemplate id="user-list">
                <PageHeader
                    right={
                        <>
                            <Button type={`primary`} size={`small`} text={`Cadastrar usuário`} svg="plus-white" action={() => setShowCreate(true)}  />
                        </>
                    }
                />
                <Box className={`no-padding`}>
                    <Table header={data.header} rows={data.rows} loading={loading} />
                </Box>
            </PanelTemplate>
        </>
    );
}

export default SuperAdminList;
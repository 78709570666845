import React, { useEffect, useState } from "react";

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import PageHeader from "../../components/PageHeader";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import { toast } from "react-toastify";
import InputCheckboxUnique from "../../components/InputCheckboxUnique";
import { ModalDelete } from "../../components/ModalDelete";
import { convertIntToMoney, convertMoenyToInt } from "../../helpers/conversions";
import InputCheckbox from "../../components/InputCheckbox";
import InputCheckboxes from "../../components/InputCheckboxes";


const PlanList = () => {
    const [loading, setLoading] = useState(true);
    const [plans, setPlans] = useState([]);
    const [data, setData] = useState({header: [], rows: []});

    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const [idToDelete, setIdToDelete] = useState(null);

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [price, setPrice] = useState(convertIntToMoney(0, true));
    const [users, setUsers] = useState(0);
    const [proposals, setProposals] = useState(0);
    const [selectedFeatures, setSelectedFeatures] = useState([]);

    const [features, setFeatures] = useState([]);

    useEffect(() => {
        getPlans();
        getFeatures();
    }, []);

    useEffect(() => {
        generateData();
    }, [plans]);

    useEffect(() => {
        if(!showCreate) clean();
    }, [showCreate]);

    useEffect(() => {
        if(!showEdit) clean();
    }, [showEdit]);

    const getPlans = () => {
        api.get('/plan').then(res => {
            setPlans(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const getFeatures = () => {
        api.get('/feature').then(res => {
            setFeatures(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then();
    }

    const generateData = () => {
        const header = ['Nome', 'Recursos', 'Usuários', 'Propostas', 'Preço'];
        const rows = [];

        plans.map(plan => {
            rows.push({
                data: [
                    plan.name,
                    `${plan.features.length} recurso${plan.features.length === 1 ? '' : 's'}`,
                    `${plan.users} usuário${plan.users === 1 ? '' : 's'}`,
                    `${plan.proposals} proposta${plan.proposals === 1 ? '' : 's'}`,
                    convertIntToMoney(plan.price, true),
                ],
                action: () => editPlan(plan)
            });
        });

        setData({header, rows});
    }

    const storePlan = () => {
        setLoading(true);

        api.post(`/plan`, {
            name,
            price: convertMoenyToInt(price),
            users,
            proposals
        }).then(res => {
            getPlans();
            setShowCreate(false);
            toast.success('Plano cadastrado com sucesso');
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const editPlan = plan => {
        setId(plan.id);
        setName(plan.name);
        setPrice(convertIntToMoney(plan.price, true));
        setUsers(plan.users);
        setProposals(plan.proposals);
        setSelectedFeatures(plan.features.map(feature => feature.id))
        setShowEdit(true);
    }

    const updatePlan = () => {
        setLoading(true);

        api.put(`/plan/${id}`, {
            name,
            price: convertMoenyToInt(price),
            users,
            proposals,
            features: selectedFeatures
        }).then(res => {
            getPlans();
            setShowEdit(false);
            toast.success('Plano atualizado com sucesso');
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const confirmDeletePlan = () => {
        setIdToDelete(id);
        setShowEdit(false);
    }

    const deletePlan = () => {
        setLoading(true);

        api.delete(`/plan/${idToDelete}`).then(res => {
            setIdToDelete(null);
            getPlans();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const clean = () => {
        setId('');
        setName('');
        setPrice(convertIntToMoney(0, true));
        setProposals(0);
        setUsers(0);
        setSelectedFeatures([]);
    }

    const handleChangeFeature = featureId => {
        const toSelectedFeatures = selectedFeatures.includes(featureId)
            ? selectedFeatures.filter(id => id !== featureId)
            : [...selectedFeatures, featureId];
    
        setSelectedFeatures(toSelectedFeatures);
    };

    return (
        <>
            <ModalDelete
                close={() => setIdToDelete(null)}
                show={idToDelete !== null}
                action={deletePlan}
                loading={loading}
            />
            <Modal
                title={`${showCreate ? 'Cadastrar' : 'Editar'} plano`}
                show={showCreate || showEdit}
                close={() => showCreate ? setShowCreate(false) : setShowEdit(false)}
                footer={
                    <>
                        {showEdit &&
                            <Button
                                type={`secondary`}
                                size={`small`}
                                text={`Excluir`}
                                svg={`delete-small`}
                                action={() => confirmDeletePlan()}
                                loading={loading} 
                            />
                        }
                        <Button
                            type={`primary`}
                            size={`small`}
                            text={showCreate ? 'Cadastrar' : 'Salvar alterações'}
                            svg={`save-white`}
                            action={() => showCreate ? storePlan() : updatePlan()}
                            loading={loading} 
                        />
                        
                    </>
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-8">
                            <Input type={`text`} label={`Nome`} value={name} change={setName}/>
                        </div>
                        <div className="col-8">
                            <Input
                                label={`Fee mensal`} 
                                value={price}
                                change={setPrice} 
                                mask={`convertToMoney`}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Input 
                                label={`Limite de usuários`} 
                                value={users}
                                change={setUsers} 
                                mask={`convertToInt`}
                            />
                        </div>
                        <div className="col-6">
                            <Input 
                                label={`Limite de propostas por mês`} 
                                value={proposals}
                                change={setProposals} 
                                mask={`convertToInt`}
                            />
                        </div>
                    </div>
                    {showEdit &&
                        <InputCheckboxes 
                            options={features} 
                            selecteds={selectedFeatures} 
                            setSelecteds={setSelectedFeatures} 
                            hideBorder={true} 
                            value={`id`}
                        />
                    }
                </div>
            </Modal>
            <PanelTemplate id="plan-list">
                <PageHeader
                    right={
                        <>
                            <Button type={`primary`} size={`small`} text={`Cadastrar plano`} svg="plus-white" action={() => setShowCreate(true)}  />
                        </>
                    }
                />
                <Box className={`no-padding`}>
                    <Table header={data.header} rows={data.rows} loading={loading} />
                </Box>
            </PanelTemplate>
        </>
    );
}

export default PlanList;